import { FetchResult } from "@apollo/client"
import { Client } from "./client"
import { ApiDef } from "./apiDef"

const websiteApiDefs = new Map<string, ApiDef>()
websiteApiDefs.set(
  "sendPixelEvent",
  new ApiDef(
    "query",
    `
  query sendPixelEvent($pixelEvent: PixelEvent!){
    SendEvent(input:$pixelEvent)
  }
`,
    false
  )
)

websiteApiDefs.set(
  "AppInstallLink",
  new ApiDef(
    "query",
    `
  query AppInstallLink($AppLinkInput: AppLinkInput!){
    AppInstallLink(input:$AppLinkInput)
  }
`,
    false
  )
)

const billingApiDefs = new Map<string, ApiDef>()
billingApiDefs.set(
  "CreatePaymentIntent",
  new ApiDef(
    "mutation",
    `mutation CreatePaymentIntent($items: [PurchaseItem!]!, $user: UserPurchaseData, $metadata: [KeyvalInput], $paymentProvider: PaymentProvider!, $couponCode: String, $giftDetails: GiftDetailsInput, $subscriptionDetails: SubscriptionInput, $paymentMethodId: String){
      CreatePaymentIntent(input:{
        items: $items
        user: $user
        metadata: $metadata
        paymentProvider: $paymentProvider
        couponCode: $couponCode
        giftDetails: $giftDetails
        subscriptionDetails: $subscriptionDetails
        paymentMethodId: $paymentMethodId
      }){
        id
        paymentIntentStatus
        clientSecret
        confirmationCode
        totalAmount
        currency
      }
    }`,
    false
  )
)

billingApiDefs.set(
  "CreateSubscription",
  new ApiDef(
    "mutation",
    `mutation CreateSubscription($items: [PurchaseItem!]!, $user: UserPurchaseData, $provider: SubscriptionProviderType!, $providerPaymentMethodId: String!, $metadata: [KeyvalInput], $couponCode: String){
      CreateSubscription(input:{
        user: $user
        items: $items
        provider: $provider
        providerPaymentMethodId: $providerPaymentMethodId
        metadata: $metadata
        couponCode: $couponCode
      }){
        id
        paymentIntentStatus
        clientSecret
        status
        confirmationCode
      }
    }`
  )
)
billingApiDefs.set(
  "CancelSubscription",
  new ApiDef(
    "mutation",
    `mutation CancelSubscription($subscriptionId: String!, $provider: SubscriptionProviderType!, $metadata: [KeyvalInput]){
      CancelSubscription(input:{
        subscriptionId: $subscriptionId
        provider: $provider
        metadata: $metadata        
      }){
        id
        status
      }
    }`
  )
)
billingApiDefs.set(
  "StripeSubscriptionRestoreCanceled",
  new ApiDef(
    "mutation",
    `mutation StripeSubscriptionRestoreCanceled($subscriptionId: String!, $provider: SubscriptionProviderType!){
      StripeSubscriptionRestoreCanceled(input:{
        subscriptionId: $subscriptionId
        provider: $provider
      }){
        id
        status
      }
    }`
  )
)
billingApiDefs.set(
  "UpdatePaymentMethod",
  new ApiDef(
    "mutation",
    `mutation UpdatePaymentMethod($paymentMethodId: String!){
      UpdatePaymentMethod(input:{
        paymentMethodId: $paymentMethodId        
      }){
        id
        clientSecret
        status
      }
    }`
  )
)
billingApiDefs.set(
  "CompleteSetupIntent",
  new ApiDef(
    "mutation",
    `mutation CompleteSetupIntent($paymentMethodId: String!){
      CompleteSetupIntent(input:{
        paymentMethodId: $paymentMethodId        
      })
    }`
  )
)
billingApiDefs.set(
  "ListPaymentMethods",
  new ApiDef(
    "query",
    `query ListPaymentMethods{
        ListPaymentMethods{
        id
        card{
          brand
          country
          exp_month
          exp_year
          last4
          created
          address{
            firstName
            lastName
            city
            country
            street
            postal
            unit
            province
            phone
          }
        }
      }
    }`
  )
)
billingApiDefs.set(
  "VerifyCoupon",
  new ApiDef(
    "query",
    `query VerifyCoupon($code: String!, $items: [PurchaseItem!]!, $affiliateId: String, $campaignId: String){
      VerifyCoupon(code: $code, items: $items, affiliateId: $affiliateId, campaignId: $campaignId) {
        discountCad
        discountUsd
        discountDays
        couponName
      }
    }`,
    false
  )
)

billingApiDefs.set(
  "CalculateOrderPrice",
  new ApiDef(
    "query",
    `query CalculateOrderPrice($input: OrderCostInput!) {
      CalculateOrderPrice(input: $input) {
        shippingCost
        taxCost
        notes
      }
    }`,
    false
  )
)

billingApiDefs.set(
  "GetInventory",
  new ApiDef(
    "query",
    `query GetInventory($input: GetInventoryInput){
        GetInventory(input: $input){
          warehouseCode
          stock
      }
    }`,
    false
  )
)

billingApiDefs.set(
  "GetOrderToConfirmAddress",
  new ApiDef(
    "query",
    `query GetOrderToConfirmAddress($token: String!){
      GetOrderToConfirmAddress(token: $token){
        id
        lineItems {
          id
          description
          currency
          quantity
          unitAmount
          priceId
          productId
          lineNumber
        }
        shippingAddress {
          id
          firstName
          lastName
          country
          province
          city
          street
          unit
          postal
          phone
          vat
        }
        email
      }
    }`,
    false
  )
)

billingApiDefs.set(
  "GetGiftOrderToConfirmAddress",
  new ApiDef(
    "query",
    `query GetGiftOrderToConfirmAddress($input: GiftOrderInput!){
      GetGiftOrderToConfirmAddress(input: $input){
        orderId
        shippingAddress {
          id
          firstName
          lastName
          country
          province
          city
          street
          unit
          postal
          phone
          vat
        }
        giftEmail
        giftFirstName
        giftLastName
      }
    }`,
    false
  )
)

billingApiDefs.set(
  "UpdateAndConfirmShippingAddress",
  new ApiDef(
    "mutation",
    `mutation UpdateAndConfirmShippingAddress($input: UpdateAndConfirmShippingAddressInput!) {
      UpdateAndConfirmShippingAddress(input: $input) {
        orderId
        shippingOrder{
          currency
          totalAmount
          clientSecret
          confirmationCode
        }
      }
    }`,
    false
  )
)

billingApiDefs.set(
  "UpdateAndConfirmGiftShippingAddress",
  new ApiDef(
    "mutation",
    `mutation UpdateAndConfirmGiftShippingAddress($input: UpdateAndConfirmGiftShippingAddressInput!) {
      UpdateAndConfirmGiftShippingAddress(input: $input)
    }`,
    false
  )
)

const accountApiDefs = new Map<string, ApiDef>()
accountApiDefs.set(
  "SignupCrm",
  new ApiDef(
    "mutation",
    `mutation SignupCrm($input: SignupCrmInput!){
      SignupCrm(input: $input)
    }`,
    false
  )
)
accountApiDefs.set(
  "requestPassRst",
  new ApiDef(
    "query",
    `query requestPassRst($email: String!) {
      requestPassRst(email: $email) 
    }`,
    false
  )
)
accountApiDefs.set(
  "CreateUser",
  new ApiDef(
    "mutation",
    `mutation CreateUser($input: UserInput!){
      CreateUser(input:$input) {
        firstName
        lastName
        email
        
        token
        exp
        updated
      }
    }`,
    false
  )
)
accountApiDefs.set(
  "UpdateUser",
  new ApiDef(
    "mutation",
    `mutation UpdateUser($input: UpdateUserInput!){
      UpdateUser(input:$input) {
        firstName
        lastName
        email
        updated {
          formatted
        }
      }
    }`
  )
)
accountApiDefs.set(
  "UpdateUserEmail",
  new ApiDef(
    "mutation",
    `mutation UpdateUserEmail($input: UpdateUserEmailInput!){
      UpdateUserEmail(input:$input) {
        email
        token
        exp
        updated {
          formatted
        }
      }
    }`
  )
)
accountApiDefs.set(
  "confirmEmail",
  new ApiDef(
    "mutation",
    `mutation confirmEmail($input: String!){
      confirmEmail(input:$input)
    }`
  )
)
accountApiDefs.set(
  "confirmEmailToken",
  new ApiDef(
    "mutation",
    `mutation confirmEmailToken($input: ConfirmEmailTokenInput!){
      confirmEmailToken(input:$input)
    }`,
    false
  )
)
accountApiDefs.set(
  "resendEmailConfirmationToken",
  new ApiDef(
    "query",
    `query resendEmailConfirmationToken($input: ResendEmailConfirmationTokenInput!){
      resendEmailConfirmationToken(input:$input)
    }`,
    false
  )
)
accountApiDefs.set(
  "resetPassword",
  new ApiDef(
    "mutation",
    `mutation resetPassword($input: ResetPassInput!){
      resetPassword(input:$input)
    }`,
    false
  )
)
accountApiDefs.set(
  "ChangePassword",
  new ApiDef(
    "mutation",
    `mutation ChangePassword($input: ChangePassInput!) {
      ChangePassword(input:$input)
    }`,
    false
  )
)
accountApiDefs.set(
  "login",
  new ApiDef(
    "query",
    `query login($email: String!, $password: String!){
      login(email: $email, password: $password) {
        token
        exp
        firstName
        lastName
        email
        membershipLevel
        
        customerAccount
        updated
      }
    }`,
    false
  )
)
accountApiDefs.set(
  "logout",
  new ApiDef(
    "mutation",
    `mutation logout{
      logout
    }`
  )
)
accountApiDefs.set(
  "CreateShippingAddress",
  new ApiDef(
    "mutation",
    `mutation CreateShippingAddress($input: CreateAddressInput!){
      CreateShippingAddress(input:$input) {
        id
      }
    }`
  )
)

accountApiDefs.set(
  "RemoveShippingAddress",
  new ApiDef(
    "mutation",
    `mutation RemoveShippingAddress($addressId: String!){
      RemoveShippingAddress(addressId: $addressId) {
        id
      }
    }`
  )
)
billingApiDefs.set(
  "CreateTestActivation",
  new ApiDef(
    "mutation",
    `mutation CreateTestActivation{
      CreateTestActivation {
        mac
        membershipId
        status
      }
    }`
  )
)
billingApiDefs.set(
  "GetUserAddresses",
  new ApiDef(
    "query",
    `query GetUserAddresses($email: String!){
      User(email: $email) {
        shippingAddresses {
          id
          firstName
          lastName
          street
          unit
          city
          province
          country
          postal
          phone
          vat
        }     
      }
    }`
  )
)
billingApiDefs.set(
  "GetOrderTracking",
  new ApiDef(
    "query",
    `query GetOrderTracking($orderId: String!) {
      GetOrderTracking(orderId: $orderId) {
        despatchDate
        courier
        service
        tackingNumbers {
          courier
          code
          url
        }
      }
    }`
  )
)
billingApiDefs.set(
  "GetUserOrders",
  new ApiDef(
    "query",
    `query GetUserOrders($email: String!, $confCode: String){
      User(email: $email) {
        firstName
        lastName
        email
        shippingAddresses {
          id
          firstName
          lastName
          country
          province
          city
          street
          unit
          postal
          phone
          vat
        }
        headset {
          id
          created {
            formatted
          }
          mac
          trialActivated
          trialStartDate {
            formatted
          }
          trialNumberDays
        }
        expiredMemberships {
          id
          expiredSubscription {
            id
            created {
              formatted
            }
            status
            cancelAtPeriodEnd
            subscriptionId
            confirmationCode
            provider
            providerPaymentMethodId
            currentPeriodEnd {
              formatted
            }
            lineItems {
              id
              description
              currency
              quantity
              unitAmount
              productId
              lineNumber
              lineType
              taxRate
            }
            invoices {
              id
              created {
                formatted
              }
              provider
              providerInvoiceId
              amountDue
              amountPaid
              billingReason
              status
              periodStart {
                formatted
              }
              periodEnd {
                formatted
              }
              tax
              total
            }
          }
        }
        membership {
          id
          created {
            formatted
          }
          status
          level
          startDate {
            formatted
          }
          endDate  {
            formatted
          }
          subscription {
            id
            trialDaysOnHeadsetConnect
            headsetConnectedTrialActivated
            created {
              formatted
            }
            status
            cancelAtPeriodEnd
            subscriptionId
            confirmationCode
            provider
            providerPaymentMethodId
            discountAmount
            couponCode
            currentPeriodEnd {
              formatted
            }
            paidUntilDate {
              formatted
            }
            lineItems {
              id
              description
              currency
              quantity
              unitAmount
              productId
              lineNumber
              lineType
              taxRate
            }
            invoices {
              id
              created {
                formatted
              }
              provider
              providerInvoiceId
              amountDue
              amountPaid
              billingReason
              status
              periodStart {
                formatted
              }
              periodEnd {
                formatted
              }
              tax
              total
            }
          }
        }      
        orders(filter:{OR: [{status_not_in:[requires_payment_method, created]}, {confirmationCode: $confCode}]}){
          id
          paymentIntentId
          created {
            formatted
          }
          confirmationCode
          paymentProvider
          status
          fulfilmentStatus
          amount
          refundAmount
          currency
          lineItems {
            id
            description
            currency
            quantity
            unitAmount
            productId
            lineNumber
            lineType
            taxRate
          }
          metadata {
            id
            key
            value
          }
          tracking {
            id
            courierName
            serviceName
            trackingNumber
            trackingUrl
          }
        }
      }      
    }`,
    true
  )
)
accountApiDefs.set(
  "FreshdeskLogin",
  new ApiDef(
    "query",
    `query FreshdeskLogin($email: String!, $password: String!, $state: String!, $nonce: String!){
      FreshdeskLogin(email: $email, password: $password, state: $state, nonce: $nonce)
    }`,
    false
  )
)
accountApiDefs.set(
  "FreshdeskSignup",
  new ApiDef(
    "mutation",
    `mutation FreshdeskSignup($input: FreshdeskSignupInput!){
      FreshdeskSignup(input:$input)
    }`,
    false
  )
)

class ServerInterface {
  clients: Map<string, Client>

  constructor() {
    this.clients = new Map<string, Client>()
    let url = process.env.GATSBY_SENSAI_SERVER_URL
    let billingUrl = url
    let accountUrl = url
    let websiteUrl = url

    if (!url || url === "localhost" || url === "undefined") {
      billingUrl = "http://localhost:2002/local"
      accountUrl = "http://localhost:2001/local"
      websiteUrl = "http://localhost:2011/local"
    }

    this.clients.set(
      "billing",
      new Client(`${billingUrl}/billing`, billingApiDefs)
    )
    this.clients.set(
      "account",
      new Client(`${accountUrl}/account`, accountApiDefs)
    )
    this.clients.set(
      "website",
      new Client(`${websiteUrl}/website`, websiteApiDefs)
    )
    //this.clients.set("crm", new Client(`${crmUrl}/crm`, crmApiDefs))
  }

  getClientDef(endPointKey: string): Client | null {
    const c = this.clients.get(endPointKey)
    if (c === null || c === undefined) {
      return null
    }
    return c
  }

  async execute(
    endPointKey: string,
    apiKey: string,
    variables: Record<string, unknown>
  ): Promise<FetchResult | null> {
    const c = this.clients.get(endPointKey)
    if (!c) {
      return null
    }
    return c.execute(apiKey, variables)
  }
}

const API = new ServerInterface()

export default API
