import { useStaticQuery, graphql } from "gatsby"
import { ProductInterface } from "../ts/interfaces"
import { Product } from "../checkout/product"

export const useProducts = () => {
  const { allSensaiProduct } = useStaticQuery(
    graphql`
      query {
        allSensaiProduct {
          nodes {
            id
            name
            stripeId
            stripeTestId
            slug
            subHeading
            description
            shortDescription
            featuredImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
            relatedProductPriceLink
            relatedProducts {
              id
            }
            CAD_Discount
            USD_Discount
            priceHeading
            outOfStock
            canUsOnly
            noCoupons
            gallery {
              url
              imageFile {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    `
  )

  const products: Product[] = []

  allSensaiProduct.nodes.forEach((p: ProductInterface) => {
    products.push(new Product(p))
  })

  return products
}
